<router>
{
    name: 'Dorset Controls Central Server',
}
</router>
<template lang="pug">
v-container
    v-row
        v-col
            hubspotSettings

</template>
<script>

import hubspotSettings from '@/components/dccs/hubspotSettings.vue';
export default {
    meta: {
        role: 'admin'
    },
    components: {
        hubspotSettings
    },
    data () {
        return {
        };
    },
    methods: {

    },
    computed: {

    },
    mounted () {
    }
};
</script>

<style scoped>
</style>
