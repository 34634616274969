<template lang="pug">
.hubspotSettings
    v-container
        v-row.ml-1
            v-col(cols='9')
                v-card(light, width='600', min-width='500')
                    v-card-title.brand.white--text.py-2 Hubspot
                        v-spacer
                        v-btn(title='Refresh', icon, dense, color='white', @click='getCurrentSettings')
                            v-icon refresh
                    v-card-text 
                        v-row
                            v-col(cols='6')
                                p(class='text-center text-h6') Settings
                                v-checkbox(label='Sync Projects', v-model='allowProjectSync', color='brand', title='When enabled, server will sync SAT project DB with HubSpot', hide-details)
                                v-checkbox(label='Write Project Comments', v-model='allowWriteComments', color='brand', title='When enabled, server will write SAT project status to HubSpot Projects as a comment', hide-details)
                            v-divider(vertical)
                            v-col(cols='6', d-flex)
                                p(class='text-center text-h6') Actions
                                v-row.mt-2(justify='center')        
                                    v-btn.white--text(color='brand', small, @click='manualDialog = true', :disabled='unsavedChanges') Manual Sync
                        v-row.mt-4(v-if='unsavedChanges')
                            v-divider
                    v-card-actions(v-if='unsavedChanges')
                        v-spacer
                        v-btn(text, color='red', @click='resetChanges') Cancel
                        v-btn.white--text(color='brand', @click='saveChanges') Save

        v-dialog(v-model='manualDialog', max-width='450')
            v-card(light)
                v-card-text
                    v-container
                        v-row(justify='center')
                            p.mb-0.pt-4 The following will be synced upon confirmation:
                        v-row.mt-n2(justify='space-around')
                            v-checkbox(label='Sync Projects', v-model='manualProjectSync', color='brand', title='When enabled, server will sync SAT project DB with HubSpot', hide-details)
                            v-checkbox(label='Write Project Comments', v-model='manualCommentSync', color='brand', title='When enabled, server will write SAT project status to HubSpot Projects as a comment', hide-details)
                v-card-actions
                    v-spacer
                    v-btn(text, @click='manualDialog = false') Cancel
                    v-btn.white--text(color='brand', @click='manualUpdate') Sync

    v-overlay(absolute, :value='busy')
        .text-h6 Please Wait...
        v-progress-linear(indeterminate)

    v-snackbar(v-model='showAlert', dark, :timeout='3000') {{ message }}
    
</template>

<script>
import _ from 'lodash';

export default {
    data () {
        return {
            dti,
            accessKey: 'admin',
            message: '',
            busy: false,
            justify: 'center',
            currentServerSettings: {},
            allowWriteComments: false,
            allowProjectSync: false,
            manualDialog: false,
            manualProjectSync: true,
            manualCommentSync: true,

        };
    },
    methods: {
        resetChanges () {
            this.getCurrentSettings();
        },

        async getCurrentSettings () {
            await this.socketEmit('getServerSettings', null, (settings) => {
                this.currentServerSettings = settings;
                this.allowWriteComments = settings.hubspotWriteComments;
                this.allowProjectSync = settings.hubspotSyncProjects;
                this.message = 'Got Hubspot Settings';
            });
        },

        async saveChanges () {
            //Get settings
            let settings = _.cloneDeep(this.currentServerSettings);

            settings.hubspotSyncProjects = this.allowProjectSync;
            settings.hubspotWriteComments = this.allowWriteComments;

            await this.socketEmit('saveServerSettings', settings, (updatedSettings) => {
                this.currentServerSettings = updatedSettings;
                this.allowWriteComments = updatedSettings.hubspotWriteComments;
                this.allowProjectSync = updatedSettings.hubspotSyncProjects;
                this.message = 'Hubspot settings updated!';
            });
        },

        manualUpdate () {
            const options = {
                manualProjectSync: this.manualProjectSync,
                manualCommentSync: this.manualCommentSync
            };

            this.socketEmit('manualHubspotUpdate', options);
            this.manualDialog = false;
            this.message = 'Manual sync began';

            //Reset sync options
            this.manualProjectSync = true;
            this.manualCommentSync = true;
        },

    },
    computed: {
        unsavedChanges () {
            let changes = false;

            if(this.allowProjectSync != this.currentServerSettings.hubspotSyncProjects) changes = true;
            if(this.allowWriteComments != this.currentServerSettings.hubspotWriteComments) changes = true;
            
            return changes;
        },
        showAlert: {
            get () {
                return this.message !== '';
            },
            set (val) {
                if (val === false) {
                    this.message = '';
                }
            },
        },
        
        
    },
    watch: {

    },
    mounted () {
        window.hubspotsettingsvm = this;
        this.getCurrentSettings();
    }
};
</script>

<style lang="scss" scoped>
</style>

<style lang="scss">
</style>
